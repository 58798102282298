<template>
    <el-dialog :title="title"
               :visible.sync="outerVisible"
               @close="close"
               width="70%">

        <div style="display: flex;justify-content: space-between;align-items: flex-start; flex-wrap: nowrap">
            <div style="width: 49%;display: inline-block;text-align: center;">
                <p style="text-align: left" v-if="customer.user"> <span style="color: blue;cursor: pointer"
                                                                        @click="editUser(customer.userId,managerConst.MODE_EDIT)">[{{customer.user.nickname}}]</span> 님문의내용</p>
                <div class="direct-chat-text" style="margin-bottom: 10px" v-html="customer.content">

                </div>
                <div class="editor" style="padding: 5px">
                    <quill-editor ref="myQuillEditor" v-model="contentReplay"
                                  :options="editorOption"
                                  @ready="onEditorReady($event)"
                                  @change="onEditorChange($event)"/>
                </div>

                <el-button @click="updateReplay" type="primary" style="width: 90%!important;margin-top: 10px">답변하기</el-button>
            </div>
            <div style="width: 50%;;display: flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap">
                <el-button type="success" size="mini" v-for="tmp in messageTempList" style="width: 30%;margin: 5px"
                           @click="setMessageContentByTemplate(tmp)">
                    {{tmp.title}}
                </el-button>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="updateReplay()">저장하기</el-button>
            <el-button @click="outerVisible = false">취소</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import managerConst from "../../../common/administrator/managerConst";
    import {getCusTempList} from "../../../network/manager/customerTemplateRequest";
    import UserInfoApplyComp from "../UserInfoApplyComp";
    import UserInfoCashPointLog from "../UserInfoCashPointLog";
    import UserInfoLoginLogComp from "../UserInfoLoginLogComp";
    import UserInfoCulCheckComp from "../UserInfoCulCheckComp";
    import UserInfoHonorCasinoBetComp from "../UserInfoHonorCasinoBetComp";
    import UserInfoTgame365BetComp from "../UserInfoTgame365BetComp";
    import {VueEditor} from "vue2-editor"
    import {getCustomerCetnerById, updateCustomerCetner} from "../../../network/manager/customercenterRequest";
    import Vue from "vue";
    import UserEditToast from "./UserEditToast";
    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "CustomerReplayToast",
        components: {
            UserInfoTgame365BetComp,
            UserInfoHonorCasinoBetComp,
            UserInfoCulCheckComp,
            UserInfoLoginLogComp,
            UserInfoCashPointLog, UserInfoApplyComp, VueEditor
        },
        data() {
            return {
                title: '',
                customerId: null, //
                customer: {contentReply: ''},
                contentReplay: '',
                closed: false,
                outerVisible: false,
                managerConst: managerConst,
                messageTempList: [],
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],         //引用，代码块
                                [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                                [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
                                [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                                [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                                [{'direction': 'rtl'}],                         // 文本方向
                                [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
                                [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
                                [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                                [{'font': []}],         //字体
                                [{'align': []}],        //对齐方式
                                ['clean'],        //清除字体样式
                            ]
                        }
                    },

                },
            }
        },
        methods: {
            replay() {

            },
            close() {
                this.customer = {contentReply: ''}
                this.customerId = null
                this.closed = true
                this.outerVisible = false
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            updateReplay() {
                this.customer.contentReply = this.contentReplay
                this.customer.status = managerConst.Customercenter.STATUS_REPLYED
                this.customer.display = managerConst.ENABLE
                updateCustomerCetner(this.customer).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '답변 완료되였습니다'
                        });
                        this.close()
                        this.$bus.$emit('customerReplayComplate')
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            onEditorChange(editor) {
                this.contentReplay = editor.html;
            },
            onEditorReady(Quill) {

            },
            setMessageContentByTemplate(temp) {
                this.contentReplay = temp.reply
            },

        },
        created() {
            this.outerVisible = true
            getCusTempList({type: managerConst.CustomercenterTemplateType.TYPE_NORMAL}, 1, 100).then(res => {
                this.messageTempList = res.data.data
            })
            getCustomerCetnerById(this.customerId).then(res => {
                this.customer = res.data.data
                if(this.customer.status == managerConst.Customercenter.STATUS_REPLYED){
                    this.contentReplay = this.customer.contentReply
                }
            })
        },
        mounted() {


        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.customer = {}
                    this.outerVisible = false
                    this.destroyElement()
                }
            }
        },

    }
</script>

<style scoped>
    .table100 td {
        padding: 5px;
        background-color: #cfcfd1 !important;
    }

    .table100 th {
        width: 20%;
    }

    .table100 .el-input__inner {
        width: 80% !important;
    }

    .table_userstatisc {
        width: 100%;
        margin-bottom: 5px;
    }

    .table_userstatisc th {
        width: 14%;
    }

    .table_userstatisc td {
        background-color: #e8e8e8 !important;
        width: 14%;
    }

</style>